import { ProposalStatus } from "@services/proposals/types";

export function generateStatusColor(status: ProposalStatus) {
    switch (status) {
        case ProposalStatus.OPEN:
            return (
                <span
                    style={{
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: '#2C88D9',
                        padding: "8px",
                        borderRadius: "20px",
                    }}>

                    {ProposalStatus.OPEN}
                </span>
            )
        case ProposalStatus.SIGNED:
            return (
                <span style={{
                    color: "white",
                    fontWeight: "bold",
                    backgroundColor: '#1AAE9F',
                    padding: "8px",
                    borderRadius: "20px",
                }}>
                    {ProposalStatus.SIGNED}
                </span>
            )
        case ProposalStatus.EXPIRED:
            return (
                <span
                    style={{
                        color: "white",
                        fontWeight: "bold",
                        backgroundColor: '#788896',
                        padding: "8px",
                        borderRadius: "20px",
                    }}>
                    {ProposalStatus.EXPIRED}
                </span>
            )
        case ProposalStatus.PENDING_APPROVAL:
            return (
                <span
                    style={{
                        fontWeight: "bold",
                        backgroundColor: '#F7C325',
                        padding: "8px",
                        borderRadius: "20px",
                    }}>
                    {ProposalStatus.PENDING_APPROVAL}
                </span>
            )
        default:
            return (
                <span
                    style={{
                        fontWeight: "bold",
                        backgroundColor: 'white',
                        padding: "8px",
                        borderRadius: "20px",
                    }}>
                    -
                </span>
            )
    }
}

export function generateLink(proposalLink: string) {
    return <a
        style={{
            color: "blue",
            textDecoration: "underline"
        }}
        target="_blank"
        rel="noopener noreferrer"
        href={`http://${proposalLink}`}>{proposalLink}</a>
}