import axios from 'axios'
import { getToken } from '@utils/auth'
import { CreateProposalContactRequest, ProposalContact } from './types'

export async function getProposalContactList() {
    try {
        const result = await axios.get<ProposalContact[]>(`${process.env.REACT_APP_BASE_URL}/proposal-contact`,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log(error)
        throw error
    }
}

export async function deleteProposalContact(id: number) {
    try {
        const result = await axios.delete(`${process.env.REACT_APP_BASE_URL}/proposal-contact/${id}`,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log(error)
        throw error
    }
}

export async function createProposalContact(body: CreateProposalContactRequest) {
    try {
        const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/proposal-contact`, body,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log(error)
        throw error
    }
}

export async function updateProposalContact(id: number, body: Partial<CreateProposalContactRequest>) {
    try {
        const result = await axios.patch(`${process.env.REACT_APP_BASE_URL}/proposal-contact/${id}`, body,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log(error)
        throw error
    }
}
