import { Button, Card, CardBody, CardHeader } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { editAgencySettings } from "@services/agencies";
import { ResponseStatus } from "@services/types";
import { AgencySettings } from "@services/agencies/types";
import CustomInput from "../CustomInput";
import { AgencySettingInputProps, AgencySettingsCardProps, EditButtonsProps } from "./types";

const checkValue = (value: string): boolean => value !== 'NaN' && parseInt(value, 10) >= 0;

const AgencySettingInput = (props: AgencySettingInputProps) => (
    <div className="flex flex-row gap-2 w-72">
        <span className="text-base w-full mr-0.5">{props.label}</span>
        <CustomInput
            type="number"
            value={props.value}
            onChange={props.onChange}
            labelPlacement="outside"
            size="sm"
            disabled={props.disabled}
            isInvalid={!checkValue(props.value)}
        />
    </div>
)

const EditButtons = (props: EditButtonsProps) => (
    <div className="flex flex-row gap-2">
        {!props.isEditing ? (
            <Button
                onClick={() => props.setIsEditing(true)}
                style={{
                    background: "#6558F5",
                    color: "white",
                }}
                >
                Edit
            </Button>
        ) : (
            <div className="flex flex-row gap-2">
                <Button
                    onClick={() => {props.onCancel(); props.setIsEditing(false)}}
                    style={{
                        background: "#FF4E4E",
                        color: "white",
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={props.onSave}
                    style={{
                        background: "#6558F5",
                        color: "white",
                    }}
                >
                    Save
                </Button>
            </div>
        )}
    </div>
)

export const AgencySettingsCard = (props: AgencySettingsCardProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isValidForm, setIsValidForm] = useState(true);

    const saveAgencySettings = async () => {
        if (!isValidForm) return;
        try {
            const response = await editAgencySettings(props.agencyId, props.agencySettings);
            if (response.status === ResponseStatus.OK) {
                props.reset()
                setIsEditing(false);
                toast.success('Agency settings successfully edited')
            } else {
                toast.error("Error while editing agency settings");
            }
        } catch (error) {
            toast.error("Error while editing agency settings");
        }
    }

    const checkNumber = (num: number | undefined) => num === undefined || (!Number.isNaN(num) && checkValue(`${num}`));

    const checkForm = (agencySettings: AgencySettings) => (
        checkNumber(agencySettings.acceptableDelayInMinutes) &&
        checkNumber(agencySettings.baseGiftcardPrice) &&
        checkNumber(agencySettings.clockInStreakPoints) &&
        checkNumber(agencySettings.treasureBoxPrice) &&
        checkNumber(agencySettings.weeklyHoursStreakPoints) &&
        checkNumber(agencySettings.referralArchiveTimeInDays) &&
        checkNumber(agencySettings.expirationPointsTimeRule) &&
        checkNumber(agencySettings.clockOutAcceptableDelayInMinutes)
    );

    useEffect(() => {
        if (checkForm(props.agencySettings)) setIsValidForm(true);
        else setIsValidForm(false);
    }, [props.agencySettings]);

    return (
        <Card>
            <CardHeader className="ml-2">
                <div className="mr-3 flex flex-row justify-between w-full items-center">
                    <span className="font-bold text-lg">Agency Settings</span>
                    <EditButtons 
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        onSave={saveAgencySettings}
                        onCancel={props.reset}
                    />
                </div>
            </CardHeader>
            <CardBody className="pt-0 mt-2">
                <div className="grid grid-cols-3 gap-4">
                    <AgencySettingInput
                        label="Treasure box price"
                        value={`${props.agencySettings.treasureBoxPrice}`}
                        onChange={(e) => props.onChangeAgencySettings('treasureBoxPrice', e.target.value)}
                        disabled={!isEditing}
                    />
                    <AgencySettingInput
                        label="Base giftcard price"
                        value={`${props.agencySettings.baseGiftcardPrice}`}
                        onChange={(e) => props.onChangeAgencySettings('baseGiftcardPrice', e.target.value)}
                        disabled={!isEditing}
                    />
                    <AgencySettingInput
                        label="Clock in streak points"
                        value={`${props.agencySettings.clockInStreakPoints}`}
                        onChange={(e) => props.onChangeAgencySettings('clockInStreakPoints', e.target.value)}
                        disabled={!isEditing}
                    />
                    <AgencySettingInput
                        label="Weekly hours streak points"
                        value={`${props.agencySettings.weeklyHoursStreakPoints}`}
                        onChange={(e) => props.onChangeAgencySettings('weeklyHoursStreakPoints', e.target.value)}
                        disabled={!isEditing}
                    />
                    <AgencySettingInput
                        label="Acceptable delay in minutes"
                        value={`${props.agencySettings.acceptableDelayInMinutes}`}
                        onChange={(e) => props.onChangeAgencySettings('acceptableDelayInMinutes', e.target.value)}
                        disabled={!isEditing}
                    />
                    <AgencySettingInput
                        label="Referral archive time in days"
                        value={`${props.agencySettings.referralArchiveTimeInDays}`}
                        onChange={(e) => props.onChangeAgencySettings('referralArchiveTimeInDays', e.target.value)}
                        disabled={!isEditing}
                    />
                    <AgencySettingInput
                        label="Expiration points time rule"
                        value={`${props.agencySettings.expirationPointsTimeRule}`}
                        onChange={(e) => props.onChangeAgencySettings('expirationPointsTimeRule', e.target.value)}
                        disabled={!isEditing}
                    />
                    <AgencySettingInput
                        label="Clock out acceptable delay"
                        value={`${props.agencySettings.clockOutAcceptableDelayInMinutes}`}
                        onChange={(e) => props.onChangeAgencySettings('clockOutAcceptableDelayInMinutes', e.target.value)}
                        disabled={!isEditing}
                    />
                </div>
            </CardBody>
        </Card>
    )
}