import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { deleteProposal } from "src/api/proposal";
import { toast } from "react-toastify";
import { Spinner } from "@nextui-org/react";
import { ProposalObject } from "@services/proposals/types";

export function ModalDeleteProposal({
    proposalId, 
    setProposal
}: {
    proposalId: number,
    setProposal: Dispatch<SetStateAction<ProposalObject | undefined>>
}) {
    const [open, setOpen] = useState<boolean>(false)
    const [querying, setQuerying] = useState<boolean>(false)
    const navigate = useNavigate();

    async function submit() {
        try {
            setQuerying(true)
            await deleteProposal(proposalId)
            setProposal(undefined)
            toast.success("Proposal successfully deleted")
            navigate("/proposal")
            setOpen(false)
        }
        catch (error) {
            toast.error("Error while deleting proposal")
        }
        finally {
            setQuerying(false)

        }
    }

    return (
        <Fragment>
            <Button variant="contained" color="error" onClick={() => setOpen(true)}>Delete</Button>
            <Dialog open={open}>
                <DialogTitle>Are you sure you want to delete this proposal?</DialogTitle>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="error" onClick={submit}>{querying ? <Spinner /> : "Delete"}</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}