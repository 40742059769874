import PageTitle from "@components/PageTitle";
import { ProposalObject, ProposalStatus as Status } from "@services/proposals/types";
import moment from "moment";
import { numberWithCommas } from "@utils/number";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getProposal } from "src/api/proposal";
import { ProposalStatus } from "../proposalStatus";
import * as S from './styles';
import { ModalEditProposal } from "../modalEditProposal";
import { ModalDeleteProposal } from "../modalDeleteProposal";

type LocationState = {
    state: ProposalObject
}

export function ProposalDetails() {
    const location = useLocation();
    const { state } = location as LocationState
    const todayDate = new Date()
    const [proposal, setProposal] = useState<ProposalObject>()
    const [proposalExpirationDate, setProposalExpirationDate] = useState<Date>()
    const [diffInDays, setDiffInDays] = useState<number>(0)
    const [estimatedCostPerMonth, setEstimatedCostPerMonth] = useState<number>(0)
    const [estimatedCostPerYear, setEstimatedCostPerYear] = useState<number>(0)
    const [baseFeeDiscount, setBaseFeeDiscount] = useState<number>(0)
    const [editProposalModal, setEditProposalModal] = useState<boolean>(false)

    function refresh() {
        getProposal(state.affinityOpportunityId).then(result => {
            setProposal(result)
            setProposalExpirationDate(result.proposalExpirationDate)
            const diff = Math.abs(new Date(result.proposalExpirationDate).getTime()) - todayDate.getTime()
            setDiffInDays(Math.round(diff / (1000 * 60 * 60 * 24)))
            setEstimatedCostPerMonth(result.finalPlatformFee * result.totalEmployee)
            setEstimatedCostPerYear(result.finalPlatformFee * result.totalEmployee * 12)
            const discount = Math.abs((result.finalImplementationFee / result.implementationFee) - 1) * 100
            setBaseFeeDiscount(discount)
        })
    }

    useEffect(() => {
        refresh()
    }, [])

    return (
        <S.mainContainer>
            {proposal &&
                <Fragment>
                    <div>
                        <S.header>
                            <PageTitle>Proposal for {proposal.name}</PageTitle>
                        </S.header>
                        <S.container>
                            <div>
                                <S.subtitles>Affinity Opportunity ID: </S.subtitles>
                                <span>{proposal.affinityOpportunityId}</span>
                            </div>
                        </S.container>
                        <S.container>
                            <div>
                                <S.subtitles>Created: </S.subtitles>
                                <span>{moment(proposal.createdAt).format('MMM DD, YYYY')}</span>
                            </div>
                            <div>
                                <S.subtitles>Expiration: </S.subtitles>
                                <span>{moment(proposalExpirationDate).format('MMM DD, YYYY')}</span>
                            </div>
                            <div>
                                <S.subtitles>Days Until Expiration: </S.subtitles>
                                <span>{diffInDays <= 0 ? "Expirated" : diffInDays}</span>
                            </div>
                        </S.container>
                        <S.container>
                            <S.title>One time fee</S.title>
                            <div>
                                <S.subtitles>Original Implementation Fee: </S.subtitles>
                                <span>${numberWithCommas(proposal.implementationFee)}</span>
                            </div>
                            <div>
                                <S.subtitles>Actual Implementation Fee: </S.subtitles>
                                <span>${numberWithCommas(proposal.finalImplementationFee)}</span>
                            </div>
                            <div>
                                <S.subtitles>Implementation Fee Discount: </S.subtitles>
                                <span>${baseFeeDiscount.toFixed(2)}%</span>
                            </div>
                        </S.container>
                        <S.container>
                            <S.title>Terms</S.title>
                            <div>
                                <S.subtitles>Terms: </S.subtitles>
                                <span>{proposal.type}</span>
                            </div>
                            <div>
                                <S.subtitles>Standard Platform Fee: </S.subtitles>
                                <span>${numberWithCommas(proposal.platformFee)}</span>
                            </div>
                            <div>
                                <S.subtitles>Actual Platform Fee: </S.subtitles>
                                <span>${numberWithCommas(proposal.finalPlatformFee)}</span>
                            </div>
                            <div>
                                <S.subtitles>Avg. Rewards Budget: </S.subtitles>
                                <span>${numberWithCommas(proposal.newAverageMonthlyReward || proposal.averageMonthlyReward)}</span>
                            </div>
                            <div>
                                <S.subtitles>Employee Amount: </S.subtitles>
                                <span>{proposal.totalEmployee}</span>
                            </div>
                            <div>
                                <S.subtitles>MRR: </S.subtitles>
                                <span>${numberWithCommas(estimatedCostPerMonth)}</span>
                            </div>
                            <div>
                                <S.subtitles>ARR: </S.subtitles>
                                <span>${numberWithCommas(estimatedCostPerYear)}</span>
                            </div>
                        </S.container>
                        <S.container>
                            <S.title>Point of Contact</S.title>
                            <div>
                                <S.subtitles>Name: </S.subtitles>
                                <span>{proposal.contact.name}</span>
                            </div>
                            <div>
                                <S.subtitles>Position: </S.subtitles>
                                <span>{proposal.contact.position}</span>
                            </div>
                            <div>
                                <S.subtitles>Email: </S.subtitles>
                                <span>{proposal.contact.email}</span>
                            </div>
                        </S.container>
                    </div>
                    <div>
                        <ProposalStatus proposal={proposal} refresh={refresh} />
                        {
                            proposal.status !== Status.SIGNED &&
                            <S.ActionsContainer>
                                <ModalEditProposal
                                    open={editProposalModal}
                                    setOpen={setEditProposalModal}
                                    proposal={proposal}
                                    setProposal={setProposal}
                                />
                                <ModalDeleteProposal
                                    setProposal={setProposal}
                                    proposalId={proposal.id}
                                />
                            </S.ActionsContainer>
                        }
                    </div>
                </Fragment>
            }
        </S.mainContainer>
    )
}