export enum ResponseStatus {
    OK = 'OK',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    NOT_FOUND = 'NOT_FOUND',
    INVALID_REQUEST = 'INVALID_REQUEST',
    UNAUTHORIZED = 'UNAUTHORIZED',
    ALREADY_EXISTS = 'ALREADY_EXISTS',
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    FORBIDDEN = 'FORBIDDEN',
    INVALID_TOKEN = 'INVALID TOKEN',
    INVALID_AGENCY_DOMAIN = 'INVALID_AGENCY_DOMAIN',
    BAD_REQUEST = 'BAD_REQUEST',
}

export type DefaultResponse = {
    status: ResponseStatus;
    message?: string;
}