import { getUser } from "@utils/auth";
import { createContext, useState } from "react";

export interface IUser {
    user: User;
    setUser: any;
};

export type User = {
    roles: string[],
    jwtToken: string;
    agencyAdmin: any,
}

export const defaultValue: IUser = {
    user: {
        agencyAdmin: null,
        jwtToken: "",
        roles: []
    },
    setUser: () => { },
};

export const UserContext = createContext<IUser>(defaultValue);

export function UserProvider({ children }: { children: any }) {
    const [user, setUser] = useState(getUser() || defaultValue.user);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
}