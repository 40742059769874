export const getUserTenure = (onboardingDate?: string) => {
  if (!onboardingDate) {
    return '';
  }
  const date = new Date(onboardingDate);
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - date.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const getUserStatus = (activated?: boolean, offboardingDate?: string) => {
  if (offboardingDate) {
    return 'Inactive';
  }
  if (activated) {
    return 'Active';
  }
  return 'Pending';
};
