import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner } from "@nextui-org/react";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react"
import { toast } from "react-toastify";
import { createProposalContact, updateProposalContact } from "src/api/proposalContact";
import { CreateProposalContactRequest, ProposalContact } from "src/api/proposalContact/types";

export function ModalProposalContact({
    isOpen,
    onOpenChange,
    refreshFunc,
    proposalContact,
}: {
    isOpen: boolean
    onOpenChange: Dispatch<SetStateAction<boolean>>
    refreshFunc: Function
    proposalContact?: ProposalContact
}) {
    const [firstName, setFirstName] = useState<string>("")
    const [lastName, setLastName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [position, setPosition] = useState<string>("")
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [querying, setQuerying] = useState<boolean>(false)
    const submitButtonText = proposalContact ? "Update" : "Create"

    function clearInputs() {
        setFirstName("")
        setLastName("")
        setEmail("")
        setPosition("")
    }

    useEffect(() => {
        if (proposalContact) {
            setFirstName(proposalContact.name.split(" ")[0])
            setLastName(proposalContact.name.split(" ")[1])
            setEmail(proposalContact.email)
            setPosition(proposalContact.position)
            setPhoneNumber(proposalContact.phoneNumber)
        }
    }, [proposalContact])

    async function submit() {
        setQuerying(true)
        if (proposalContact) {
            try {
                const updateProposalContactRequest: Partial<CreateProposalContactRequest> = {
                    name: `${firstName} ${lastName}`,
                    email,
                    position,
                    phoneNumber,
                }
                await updateProposalContact(proposalContact.id, updateProposalContactRequest)
                toast.success("Proposal contact succesfully updated");
                onOpenChange(false)
            }
            catch (error) {
                console.log(error)
                toast.error("Error while updating proposal contact");
            }
            finally {
                setQuerying(false)
            }
        }
        else {
            try {
                const createProposalContactRequest: CreateProposalContactRequest = {
                    name: `${firstName} ${lastName}`,
                    email,
                    position,
                    phoneNumber,
                }
                await createProposalContact(createProposalContactRequest)
                toast.success("Proposal contact succesfully created");
                onOpenChange(false)
            }
            catch (error) {
                console.log(error)
                toast.error("Error while creating proposal contact");
            }
            finally {
                setQuerying(false)
            }
        }
        clearInputs()
        refreshFunc()
    }

    function validate() {
        if (proposalContact) return false
        if (!firstName || !lastName || !email || !position || !phoneNumber) return true
        return false
    }

    return (
        <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
                {onClose => (
                    <Fragment>
                        <ModalHeader>{proposalContact ? "Update Proposal contact" : "Adding Proposal contact"}</ModalHeader>
                        <ModalBody>
                            <Input
                                onChange={e => setFirstName(e.target.value)}
                                value={firstName}
                                type="text"
                                label="First name"
                                placeholder="Enter the first name" />
                            <Input
                                onChange={e => setLastName(e.target.value)}
                                value={lastName}
                                type="text"
                                label="Last name"
                                placeholder="Enter the last name" />
                            <Input
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                type="email"
                                label="Email"
                                placeholder="Enter the email" />
                            <Input
                                onChange={e => setPosition(e.target.value)}
                                value={position}
                                type="text"
                                label="Position"
                                placeholder="Enter the position" />
                            <Input
                                onChange={e => setPhoneNumber(e.target.value)}
                                value={phoneNumber}
                                type="text"
                                label="Phone Number"
                                placeholder="Enter the phone number" />
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="light" onPress={onClose}>
                                Cancel
                            </Button>
                            <Button isDisabled={validate()} color="primary" onPress={submit}>
                                {querying ? <Spinner color="white" /> : submitButtonText}
                            </Button>
                        </ModalFooter>
                    </Fragment>
                )}
            </ModalContent>
        </Modal>
    )
}