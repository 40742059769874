import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner } from "@nextui-org/react";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { toast } from "react-toastify";
import { deleteProposalContact } from "src/api/proposalContact";
import { ProposalContact } from "src/api/proposalContact/types";

export function ModalConfirmDelete({
    refreshFunc,
    isOpen,
    onOpenChange,
    proposalContact,
}: {
    refreshFunc: Function
    isOpen: boolean
    onOpenChange: Dispatch<SetStateAction<boolean>>
    proposalContact: ProposalContact
}) {
    const [querying, setQuerying] = useState<boolean>(false)

    async function deleteProposalContract() {
        try {
            setQuerying(true)
            await deleteProposalContact(proposalContact.id)
            toast.success("Successfully deleted proposal contact!");
            onOpenChange(false)
            refreshFunc()
        }
        catch (error) {
            console.log("Error deleting proposal contact")
            toast.error("Error while deleting proposal contact");
        }
        finally {
            setQuerying(false)
        }
    }

    return (
        proposalContact &&
        <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
                {onClose => (
                    <Fragment>
                        <ModalHeader>Are you sure you want to delete this Proposal contact?</ModalHeader>
                        <ModalBody>
                            <span>Delete: <b>{proposalContact.name}</b></span>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="light" onPress={onClose}>
                                Cancel
                            </Button>
                            <Button color="danger" onPress={deleteProposalContract}>
                                {querying ? <Spinner color="white" /> : "Delete"}
                            </Button>
                        </ModalFooter>
                    </Fragment>
                )}
            </ModalContent>
        </Modal>
    )
}