import GoBackButton from "@components/GoBackButton";
import PageTitle from "@components/PageTitle";
import { Button, Card, CardBody, CardHeader, Link } from "@nextui-org/react";
import { getAgencyDetails } from "@services/agencies";
import { AgencyDetailsObject, AgencySettings } from "@services/agencies/types";
import { formatDate } from "@utils/date";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AgencySettingsCard } from "./AgencySettingsCard";

const AgencyDetais = () => {
  const [agency, setAgency] = useState<AgencyDetailsObject>();
  const [agencySettings, setAgencySettings] = useState<AgencySettings>();
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;

  const getAgency = async () => {
    if (!id) return;
    const agencyResponse = await getAgencyDetails(id);
    if (agencyResponse) setAgency(agencyResponse);
    if (agencyResponse) {
      setAgencySettings({
        treasureBoxPrice: agencyResponse.treasureBoxPrice,
        baseGiftcardPrice: agencyResponse.baseGiftcardPrice,
        clockInStreakPoints: agencyResponse.clockInStreakPoints,
        weeklyHoursStreakPoints: agencyResponse.weeklyHoursStreakPoints,
        acceptableDelayInMinutes: agencyResponse.acceptableDelayInMinutes,
        referralArchiveTimeInDays: agencyResponse.referralArchiveTimeInDays,
        expirationPointsTimeRule: agencyResponse.expirationPointsTimeRule,
        clockOutAcceptableDelayInMinutes: agencyResponse.clockOutAcceptableDelayInMinutes,
      })
    }
  };

  const onChangeAgencySettings = (key: string, value: string) => {
    setAgencySettings({
      ...agencySettings,
      [key]: value === '' ? NaN : parseInt(value, 10),
    });
  }

  const formatCurrency = (valueInCents: number) => {
    const valueInDollars = valueInCents / 100;
    return valueInDollars.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  useEffect(() => {
    getAgency();
  }, [id]);


  return (
    <div className="w-full flex flex-col gap-5">
      <GoBackButton
        label="Agencies"
        navigatePath={`/agencies`}
      />
      <div className="flex flex-row justify-between">
        <PageTitle>{agency?.name}</PageTitle>
        <Button
          onClick={() => navigate(`/agencies/${id}/sms-manager`)}
          style={{
            background: "#6558F5",
            color: "white",
          }}
        >
          Manage SMS
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-4">
          <Card>
            <CardHeader className="ml-2">
              <span className="font-bold text-lg">Details</span>
            </CardHeader>
            <CardBody className="pt-0">
              <div className="flex flex-col gap-2">
                <span className="text-sm">Launch Date: <span className="font-semibold">{formatDate(agency?.launchDate || '')}</span></span>
                <span className="text-sm">Point Name: <span className="font-semibold">{agency?.pointName}</span></span>
                <span className="text-sm">App Name: <span className="font-semibold">{agency?.appName}</span></span>
                <span className="text-sm">EMR: <span className="font-semibold">{agency?.dataProvider}</span></span>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader className="ml-2">
              <span className="font-bold text-lg">Links</span>
            </CardHeader>
            <CardBody className="pt-0">
              <div className="flex flex-col gap-2">
                <span className="text-base">
                  <Link
                    isExternal
                    showAnchorIcon
                    className="cursor-pointer"
                    href={agency?.dashboardUrl}
                  >
                    Admin Dashboard
                  </Link>
                </span>
                <span className="text-base flex">
                  <Link isExternal showAnchorIcon className="cursor-pointer" href={agency?.appUrl}>
                    Employee App
                  </Link>
                </span>
                <span className="text-base">
                  <Link
                    isExternal
                    showAnchorIcon
                    className="cursor-pointer"
                    href={agency?.engagementSurveyUrl}
                  >
                    Engagement Survey
                  </Link>
                </span>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader className="ml-2">
              <span className="font-bold text-lg">Balance</span>
            </CardHeader>
            <CardBody className="pt-0">
              <span className="font-bold text-4xl">{formatCurrency(agency?.balance ?? 0)}</span>
            </CardBody>
          </Card>
      </div>
      {id && agencySettings && <AgencySettingsCard
        agencyId={parseInt(id, 10)}
        agencySettings={agencySettings}
        onChangeAgencySettings={onChangeAgencySettings}
        reset={getAgency}
      />}
    </div>
  );
};

export default AgencyDetais;
