import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Input } from "@nextui-org/react";
import { login } from "@services/login";
import { LoginObject } from "@services/login/types";
import { setToken, setUserOnLocalStorage } from "@utils/auth";
import { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UserContext } from "src/context/userContext";
import * as S from "./styles";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginObject>();
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const { setUser } = useContext(UserContext);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const onSubmit: SubmitHandler<LoginObject> = async (data: LoginObject) => {
    setError('');
    const response = await login(data);
    if (!response.jwtToken) {
      setError('Email or password is incorrect');
    } else {
      setToken(response.jwtToken);
      setUserOnLocalStorage({
        agencyAdmin: response.agencyAdmin,
        roles: response.roles,
        jwtToken: response.jwtToken
      })
      setUser({
        agencyAdmin: response.agencyAdmin,
        roles: response.roles,
        jwtToken: response.jwtToken
      })

      navigate("/");
    }
  };

  return (
    <S.Container>
      <S.LoginForm onSubmit={handleSubmit(onSubmit)}>
        <img width={'50%'} src="/img/perry-logo-colored.png" alt="" className="mb-12" />
        <div className="flex flex-col gap-4">
          <div className="flex w-full flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Input
              label="Email"
              type="email"
              labelPlacement="outside"
              variant="bordered"
              validationState={errors.email ? 'invalid' : 'valid'}
              {...register('email', { required: true })}
              className="mb-8"
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex w-full flex-wrap md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Input
              label="Password"
              type={isVisible ? 'text' : 'password'}
              labelPlacement="outside"
              variant="bordered"
              validationState={errors.password ? 'invalid' : 'valid'}
              {...register('password', { required: true })}
              endContent={
                <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                  {isVisible ? <Visibility /> : <VisibilityOff />}
                </button>
              }
              className="mb-8"
            />
          </div>
        </div>
        <Button
          style={{
            background:
              'linear-gradient(205deg, #7921A2 30.00%, rgba(159, 36, 128, 0.80) 65.63%, rgba(215, 39, 78, 0.50) 100%)',
            color: 'white',
          }}
          type="submit"
        >
          Login
        </Button>
        <S.FormError>{error && error}</S.FormError>
      </S.LoginForm>
      <S.LoginBackground>
        <img src="img/logo-icon-white.png" width={'365px'} height={'221px'} alt="" />
      </S.LoginBackground>
    </S.Container>
  );
};

export default Login;
