import { Input, InputProps, cn } from "@nextui-org/react";

const CustomInput = (props: InputProps) => {
    const {children, ...otherProps} = props;

    return (
        <Input
        className="w-16"
        {...otherProps}
        classNames={{
            mainWrapper: cn(
                'flex-row items-center justify-start gap-2 w-16'
            ),
            inputWrapper: cn(
                'h-full w-16 p-1.5'
            ),
            input: props.disabled ? cn(
                'text-gray-500'
            ) : cn(
                'text-black'
            )
        }}
        >
            {children}
        </Input>
    );
};

export default CustomInput;