import { Button } from '@nextui-org/react';
import { ContentCopy } from '@mui/icons-material';
import { CopyToClipboardButtonProps } from './types';

const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.text);
  }

  return (
    <Button variant="light" size="sm" isIconOnly onClick={copyToClipboard}>
      <ContentCopy fontSize="small" />
    </Button>
  );
};

export default CopyToClipboardButton;
