import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { GoBackButtonProps } from './types';

const GoBackButton = (props: GoBackButtonProps) => {
  const navigate = useNavigate();
  const navigateFunction = () => {
    if (props.navigatePath) {
      navigate(props.navigatePath);
    } else {
      navigate(-1);
    }
  }

  return (
    <div className='width-full flex-row'>
      <S.BackButton onClick={navigateFunction}>
        <ArrowBack />
        <span>{props.label}</span>
      </S.BackButton>
    </div>
  );
};

export default GoBackButton;
