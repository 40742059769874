import { Logout } from "@mui/icons-material";
import { clearUserOnLocalStorage, removeToken } from "@utils/auth";
import { useContext } from "react";
import { UserContext } from "src/context/userContext";
import * as S from "./styles";

const LogoutButton = () => {
  const { setUser } = useContext(UserContext);

  const logout = () => {
    removeToken();
    clearUserOnLocalStorage();
    window.location.reload();
    setUser({
      user: null,
      roles: [],
      jwtToken: ""
    })
  };
  return (
    <S.LogoutButtonContainer onClick={() => logout()}>
      <Logout /> Logout
    </S.LogoutButtonContainer>
  );
};

export default LogoutButton;
