import axios from "axios";
import { getToken } from "@utils/auth";
import { CreateAgencyRequest } from "./types";

export async function createAgency(body: CreateAgencyRequest) {
    try {
        const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/agency/create`, body,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log(error)
        throw error
    }
}