export const formatDate = (date: string) => {
  if (!date) return '-';
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  return `${month}/${day}/${year}`;
};

export const formatDateAndTime = (date: string) => {
  if (!date) return '-';
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();
  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
};
