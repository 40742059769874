import PageTitle from '@components/PageTitle';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  getKeyValue,
  useDisclosure,
} from '@nextui-org/react';
import { editUser, getUserDetails } from '@services/users';
import { UserDetailsObject } from '@services/users/types';
import { getUserStatus, getUserTenure } from '@utils/user';
import { Key, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsyncList } from '@react-stately/data';
import { formatDateAndTime } from '@utils/date';
import { Edit, PersonOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import { formatPhoneNumber } from '@utils/phone';
import { PointRewardType, RedeemedTypes, pointsRewardsType, redeemedType } from 'src/types/points';
import { GiftCardBrands, pointsEarnedColumns, pointsRedeemedColumns } from '../utils';
import { DeactivateUserModal } from '../DeactivateUserModal';

const UserDetails = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<UserDetailsObject>();
  const [selected, setSelected] = useState<any>('earned');
  const [isLoading, setIsLoading] = useState(true);
  const [tableColumns, setTableColumns] = useState<any>(pointsEarnedColumns);
  const [phoneNumber, setPhoneNumber] = useState<any>('');
  const [deactivateModalOpen, setDeactivateModalOpen] = useState<boolean>(false);

  const getUser = async () => {
    if (!id) return;
    const userResponse: UserDetailsObject = await getUserDetails(id);
    if (userResponse) setUser(userResponse);
    setIsLoading(false);
  };

  const list = useAsyncList({
    load() {
      if (!user) return { items: [] };
      const items = selected === 'earned' ? user.pointsRewards : user.giftcardRedeems;
      return { items };
    },

    sort({ items, sortDescriptor }: { items: any; sortDescriptor: any }) {
      return {
        items: Object.assign(
          items.sort((a: any, b: any) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            let cmp = (parseInt(first, 10) || first) < (parseInt(second, 10) || second) ? -1 : 1;
            if (sortDescriptor.direction === 'descending') {
              cmp *= -1;
            }
            return cmp;
          }),
          {}
        ),
      };
    },
  });

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    setTableColumns(selected === 'earned' ? pointsEarnedColumns : pointsRedeemedColumns);
    list.items = [];
    list.reload();
  }, [user, selected]);

  const renderCell = useCallback((userData: any, columnKey: Key) => {
    switch (columnKey) {
      case 'created':
        return formatDateAndTime(userData.created);
      case 'awarded':
        return formatDateAndTime(userData.awarded);
      case 'type':
        return (
          pointsRewardsType[userData.type as PointRewardType] ||
          redeemedType[userData.type as RedeemedTypes] ||
          userData.type
        );
      case 'giftcardType':
        return GiftCardBrands[userData.giftcardType] || 'NOT FOUND';
      default:
        return getKeyValue(userData, columnKey);
    }
  }, []);

  const handleConfirm = () => {
    if (!user?.id || user.phoneNumber === phoneNumber) {
      onClose();
      return;
    }
    if (!phoneNumber || phoneNumber.length !== 12) {
      onClose();
      toast.warning('Please enter a valid phone number');
      return;
    }
    editUser({ id: user?.id, phoneNumber })
      .then((response) => {
        if (response.status === 'OK') {
          const newUser = user;
          newUser.phoneNumber = phoneNumber;

          setUser({ ...newUser });
          toast.success('Number updated successfully');
          onClose();
        }
      })
      .catch(() => {
        toast.error('An error occurred');
      });
  };

  const handleOpen = () => {
    setPhoneNumber(user?.phoneNumber || '');
    onOpen();
  };

  const onCloseDeactivateModal = () => {
    setDeactivateModalOpen(false);
    getUser();
  }

  return (
    <div>
      <PageTitle>
        <div className="flex flex-row justify-between">
          <div>
            <span>{`${user?.firstName} ${user?.surname}`}</span>
            <p className="text-base font-normal flex items-center">
              {user?.totalPoints} <Image src={user?.agency.pointSVGPath} width={30} height={30} />
            </p>
          </div>
          {user?.activated &&
            <Button color="secondary" variant="bordered" onClick={() => setDeactivateModalOpen(true)}>
              <PersonOff
                color="secondary"
              />
              Deactivate
            </Button>
          }
        </div>
      </PageTitle>
      <div className="flex flex-column mt-4">
        <Card className="w-full">
          <CardHeader className="ml-2">
            <p className="text-lg font-semibold">Overview</p>
          </CardHeader>
          <CardBody className="gap-2">
            <span>Agency: {user?.agency.name}</span>
            <span>
              Mobile number: {formatPhoneNumber(user?.phoneNumber || '')}{' '}
              <span onClick={handleOpen}>
                <Edit color="secondary" className="cursor-pointer" />
              </span>
            </span>
            <span>Email: {user?.email}</span>
            <span>Tenure: {getUserTenure(user?.onboardingDate || undefined)} days</span>
            <span>Status: {getUserStatus(user?.activated, user?.offboardingDate)}</span>
          </CardBody>
        </Card>
      </div>
      <div className="mt-4">
        <Tabs
          color="secondary"
          className="pl-3"
          aria-label="Tabs colors"
          size="sm"
          onSelectionChange={setSelected}
        >
          <Tab key="earned" title="Earned" />
          <Tab key="redeemed" title="Redeemed" />
        </Tabs>
        <Table
          aria-label="Users table"
          isStriped
          shadow="md"
          sortDescriptor={list.sortDescriptor}
          onSortChange={list.sort}
          classNames={{
            base: 'max-h-[70vh] overflow-auto p-3',
            table: 'max-h-[50vh]',
          }}
        >
          <TableHeader columns={tableColumns}>
            {(column: any) => (
              <TableColumn className="bg-purple-500 text-white" allowsSorting key={column.key}>
                {column.label}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody
            isLoading={isLoading}
            items={list.items}
            loadingContent={<Spinner color="secondary" />}
            emptyContent={'No rows to display.'}
          >
            {(item: any) => (
              <TableRow key={item.publicId}>
                {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Modal size="md" isOpen={isOpen} onClose={onClose}>
          <ModalContent>
            {() => (
              <>
                <ModalHeader className="flex flex-col gap-1">Edit user</ModalHeader>
                <ModalBody>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    className="pl-1"
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="light" onClick={onClose}>
                    Close
                  </Button>
                  <Button color="primary" onPress={handleConfirm}>
                    Confirm
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
      <DeactivateUserModal isOpen={deactivateModalOpen} onClose={onCloseDeactivateModal} user={user!} />
    </div>
  );
};

export default UserDetails;
