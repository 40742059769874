import { ApproveProposalRequest, CreateProposalRequest, ProposalObject, UpdateProposalRequest } from '@services/proposals/types'
import { getToken } from '@utils/auth'
import axios from 'axios'
import { GetAffinityOpportunityListResponse } from './types'

export async function getProposalList() {
    try {
        const result = await axios.get<ProposalObject[]>(`${process.env.REACT_APP_BASE_URL}/proposal`,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log("Error while querying proposals: ", error)
        throw error
    }
}

export async function getProposalUnassociatedList() {
    try {
        const result = await axios.get<ProposalObject[]>(`${process.env.REACT_APP_BASE_URL}/proposal/unassociated`,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log("Error while querying proposals: ", error)
        throw error
    }
}

export async function getAffinityCopportunities() {
    try {
        const result = await axios.get<GetAffinityOpportunityListResponse>(`${process.env.REACT_APP_BASE_URL}/proposal/affinity-opportunities`,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log("Error while querying affinity opportunities: ", error)
        throw error
    }
}

export async function createProposal(body: CreateProposalRequest) {
    try {
        const result = await axios.post<ProposalObject>(`${process.env.REACT_APP_BASE_URL}/proposal`, body,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log("Error while creating proposal: ", error);
        throw error
    }
}

export async function deleteProposal(id: number) {
    try {
        const result = await axios.delete(`${process.env.REACT_APP_BASE_URL}/proposal/opportunity/${id}`,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log("Error while deleteing proposal: ", error);
        throw error
    }
}

export async function updateProposal(body: UpdateProposalRequest) {
    try {
        const result = await axios.patch<ProposalObject>(`${process.env.REACT_APP_BASE_URL}/proposal/update/${body.id}`, body,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log("Error while updating proposal: ", error);
        throw error
    }
}

export async function approveProposal(body: ApproveProposalRequest) {
    try {
        const result = await axios.patch<ProposalObject>(`${process.env.REACT_APP_BASE_URL}/proposal/approve`, body,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log("Error while approving proposal: ", error);
        throw error
    }
}

export async function getProposal(id: number) {
    try {
        const result = await axios.get<ProposalObject>(`${process.env.REACT_APP_BASE_URL}/proposal/opportunity/${id}`,
            { headers: { Authorization: `Bearer ${getToken()}` } })
        return result.data
    }
    catch (error) {
        console.log("Error while querying specific proposal: ", error)
        throw error
    }
}
