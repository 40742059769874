import { apiRoutes } from '@services/constants';
import * as fetch from '@utils/fetch';
import { DeactivateUserObject, EditUserObject, UserDetailsObject, UsersResponse } from './types';

export const getUsers = (
  cursor: any,
  limit: string,
  key: string,
  selectedAgency: string,
  filter?: string,
): Promise<UsersResponse> => fetch.get<UsersResponse>(apiRoutes.users, {
    page: cursor,
    limit,
    status: key,
    filter: filter ? filter.toLowerCase() : '',
    agency: selectedAgency,
  });

export const getUserDetails = async (id: string): Promise<UserDetailsObject> => {
  const response = await fetch.get<UserDetailsObject>(`${apiRoutes.users}/${id}`);
  return response;
};

export const editUser = ( user: EditUserObject): Promise<{ message: string; status: string }> => 
  fetch.patch<EditUserObject, { message: string; status: string }>(`${apiRoutes.users}`, user);

export const deactivateUser = (publicId: string): Promise<{ message: string; status: string }> => 
  fetch.patch<DeactivateUserObject, { message: string; status: string }>(`${apiRoutes.users}/deactivate`, { publicId });