import { apiRoutes } from '@services/constants';
import * as fetch from '@utils/fetch';
import { DefaultResponse } from '@services/types';
import { AgenciesResponse, AgencyDetailsObject, AgencyLoginResponse, AgencyObject, AgencySettings } from './types';

export const getAgencies = async (
  cursor: any,
  limit: string,
  key: string,
  filter?: string
): Promise<AgencyObject[]> => {
  const response = await fetch.get<AgenciesResponse>(apiRoutes.agencies, {
    page: cursor,
    limit,
    filter: filter || '',
    status: key,
  });
  return response.items;
};

export const getAgencyDetails = async (agencyId: string): Promise<AgencyDetailsObject> => {
  const response = await fetch.get<AgencyDetailsObject>(`${apiRoutes.agencies}/${agencyId}`);
  return response;
};

export const loginWithAgency = async (agencyId: number): Promise<AgencyLoginResponse> => {
  const response = await fetch.get<AgencyLoginResponse>(`${apiRoutes.agencies}/${agencyId}/login`);
  return response;
};

export const editAgencySettings = async (agencyId: number, agencySettings: AgencySettings): Promise<DefaultResponse> => {
  const response = await fetch.patch<AgencySettings, DefaultResponse>(`${apiRoutes.agencySettings}/${agencyId}`, agencySettings);
  return response;
}
