import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;