import { SmsColumnsKeys } from "./types";

export const smsColumns = [
    {
        key: SmsColumnsKeys.MESSAGE,
        label: 'Message',
    },
    {
        key: SmsColumnsKeys.AUDIENCE,
        label: 'Audience',
    },
    {
        key: SmsColumnsKeys.DATE,
        label: 'Date and Time',
    },
    {
        key: SmsColumnsKeys.STATUS,
        label: 'Status',
    },
    {
        key: SmsColumnsKeys.ACTIONS,
        label: '',
    }
];