import { Button, Spinner } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createProposal, getAffinityCopportunities } from "src/api/proposal";
import { FormattedOrganizations } from "src/api/proposal/types";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { CreateProposalRequest } from "@services/proposals/types";
import * as S from "./styles";

export function ModalProposal({
    isOpen,
    onClose,
    refresh,
}: {
    isOpen: boolean
    onClose: () => void
    refresh: () => void
}) {
    const [affinityOpportunityList, setAffinityOpportunityList] = useState<FormattedOrganizations[]>([])
    const [affinityOpportunityId, setAffinityOpportunityId] = useState<number>()
    const [querying, setQuerying] = useState<boolean>(false)

    useEffect(() => {
        getAffinityCopportunities().then(result => {
            const formattedOpportunity: FormattedOrganizations[] = result.opportunities.map(opportunity => ({ name: opportunity.name, id: opportunity.id }))
            setAffinityOpportunityList(formattedOpportunity)
        })
    }, [])

    async function submit() {
        if (affinityOpportunityId) {
            try {
                const createProposalRequest: CreateProposalRequest = { affinityOpportunityId }
                setQuerying(true)
                await createProposal(createProposalRequest)
                await refresh()
                toast.success("Proposal succesfully created");
                setAffinityOpportunityId(undefined)
                onClose()
            }
            catch (error: any) {
                toast.error(error?.response?.data?.customMessage ?? "Error while creating proposal");
                throw error
            }

            finally {
                setQuerying(false)
            }
        }
    }

    function validate() {
        if (querying) return true
        if (!affinityOpportunityId) return true
        return false
    }

    return (
        <Dialog fullWidth open={isOpen} onClose={onClose}>
            <DialogTitle>
                New Proposal
            </DialogTitle>
            <DialogContent>
                <S.centered>
                    <S.DropdownText>Affinity Opportunity</S.DropdownText>
                    <FormControl style={{ flexGrow: 1 }}>
                        <InputLabel>Affinity Opportunity</InputLabel>
                        <Select
                            value={affinityOpportunityId}
                            label="Affinity Opportunity"
                            onChange={(e) => setAffinityOpportunityId(e.target.value as number)}
                        >
                            {affinityOpportunityList.map((affinityOpportunity, index: number) =>
                                <MenuItem key={`menu-item-${index}`} value={affinityOpportunity.id}>{affinityOpportunity.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </S.centered>
            </DialogContent>
            <DialogActions>
                <Button variant="light" onClick={() => {
                    setAffinityOpportunityId(undefined)
                    onClose()
                }}>
                    Cancel
                </Button>
                <Button isDisabled={validate()} color="primary" onPress={submit}>
                    {querying ? <Spinner color="white" /> : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}