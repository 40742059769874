import { Fragment, useContext, useState } from "react";
import { Image } from "@nextui-org/react";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import LogoutButton from "@components/LogoutButton";
import { UserContext } from "../../context/userContext";
import * as S from "./styles";
import { Role } from "./types";

type SidebarItem = {
  name: string;
  key: string;
};

const Sidebar = () => {
  const items: SidebarItem[] = [
    {
      name: 'Home',
      key: '',
    },
    {
      name: 'Agencies',
      key: 'agencies',
    },
    {
      name: 'Users',
      key: 'users',
    },
    {
      name: "Proposal",
      key: "proposal",
    },
    {
      name: "Proposal Contact",
      key: "proposal-contact",
    }
  ];
  const { user } = useContext(UserContext)

  const navigate = useNavigate();
  const [currentItem, setCurrentItem] = useState<SidebarItem>(
    items.find((item) => item.key === window.location.pathname.split("/")[1]) ||
    items[0]
  );
  const handleItemClick = (item: SidebarItem) => {
    setCurrentItem(item);
    navigate(`/${item.key}`);
  };

  function generateMenuItemsByRoles() {
    if (user && user.agencyAdmin) {
      if (user.agencyAdmin.role === Role.SUPER_ADMIN) {
        return items.map((item) =>
          <S.Item
            key={item.key}
            className={classnames({ active: currentItem.key === item.key })}
            onClick={() => handleItemClick(item)}
          >
            {item.name}
          </S.Item>
        )
      }
      return items.map((item: SidebarItem, index: number) => {
        if (user.roles.includes(item.name)) {
          return (
            <S.Item
              key={item.key}
              className={classnames({ active: currentItem.key === item.key })}
              onClick={() => handleItemClick(item)}
            >
              {item.name}
            </S.Item>
          )
        }
        return <Fragment key={`role-${index}`} />
      })
    }
    return undefined
  }

  return (
    <div className="flex flex-col justify-between h-full fixed">
      <div>
        <div className="grid justify-items-center mt-8 px-7">
          <Image src="img/perry-logo-colored.png" width={'150px'} alt="Logo" />
        </div>
        <S.ItemsContainer>
          {generateMenuItemsByRoles()}
        </S.ItemsContainer>
      </div>
      <div className="p-10">
        <LogoutButton />
      </div>
    </div>
  );
};

export default Sidebar;
