import GoBackButton from "@components/GoBackButton";
import PageTitle from "@components/PageTitle";
import { Button, Tab, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tabs, Tooltip } from "@nextui-org/react";
import { getAgencyDetails } from "@services/agencies";
import { AgencyDetailsObject } from "@services/agencies/types";
import { Key, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import { deleteSmsJob, getSmsJobs } from "src/api/smsJob";
import { toast } from "react-toastify";
import { SmsColumnsKeys, SmsItem, SmsStatus } from "./types";
import { smsColumns } from "./consts";
import { SmsAudience } from "../NewSms/types";

const AgencySmsList = () => {
  const [agency, setAgency] = useState<AgencyDetailsObject>();
  const [selected, setSelected] = useState<SmsStatus>(SmsStatus.SCHEDULED);
  const [fullSmsList, setFullSmsList] = useState<SmsItem[]>([]);
  const [selectedSmsList, setSelectedSmsList] = useState<SmsItem[]>([]);
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const getAgency = async () => {
    if (!id) return;
    const agencyResponse = await getAgencyDetails(id);
    if (agencyResponse) setAgency(agencyResponse);
  };

  const getSmsJobsList = async () => {
    if (!agency?.publicId) return;
    const fullList = await getSmsJobs(agency.publicId);
    const sortedList = fullList.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    if (sortedList) setFullSmsList(sortedList);
  };

  const deleteSms = async (smsId: string) => {
    try {
      await deleteSmsJob(smsId);
      await getAgency();
      await getSmsJobsList();
      toast.success('SMS succesfully deleted');
    }
    catch (error) {
      toast.error('Error while deleting SMS');
      throw error
    }
  }

  useEffect(() => {
    getAgency();
  }, [id]);

  useEffect(() => {
    getSmsJobsList();
  }, [agency]);

  useEffect(() => {
    setSelectedSmsList(fullSmsList.filter(sms => sms.status === selected))
  }, [selected, fullSmsList])

  const renderCell = useCallback((smsItem: SmsItem, columnKey: Key) => {
    const getSmsAudienceText = (audience: SmsAudience) => {
      if (audience === SmsAudience.ALL) return 'All';
      if (audience === SmsAudience.ENROLLED) return 'Enrolled';
      if (audience === SmsAudience.UNENROLLED) return 'Unenrolled';
      return '';
    }

    const getStatusText = (status: SmsStatus) => {
      if (status === SmsStatus.SCHEDULED) return 'Scheduled';
      if (status === SmsStatus.FAILED) return 'Failed';
      if (status === SmsStatus.SENT) return 'Sent';
      return '';
    }

    let filterType: SmsAudience = SmsAudience.ALL;
    if (smsItem.filterType === SmsAudience.ENROLLED) filterType = SmsAudience.ENROLLED;
    if (smsItem.filterType === SmsAudience.UNENROLLED) filterType = SmsAudience.UNENROLLED;

    let smsStatus: SmsStatus = SmsStatus.SCHEDULED;
    if (smsItem.status === SmsStatus.SENT) smsStatus = SmsStatus.SENT;
    if (smsItem.status === SmsStatus.FAILED) smsStatus = SmsStatus.FAILED;

    const smsDate = new Date(smsItem.date);
    const smsDateFormatted = smsDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    const MAX_MESSAGE_LENGTH = 150;

    switch (columnKey) {
      case SmsColumnsKeys.MESSAGE:
        return (
          <div>
            {smsItem.message.length > MAX_MESSAGE_LENGTH ?
              (
                <div className="whitespace-pre-line">
                  {`${smsItem.message.substring(0, MAX_MESSAGE_LENGTH)}...`}
                </div>
              ) :
              <div className="whitespace-pre-line">{smsItem.message}</div>
            }
          </div>
        );
      case SmsColumnsKeys.AUDIENCE:
        return getSmsAudienceText(filterType);
      case SmsColumnsKeys.DATE:
        return <div className="whitespace-nowrap">{smsDateFormatted}</div>;
      case SmsColumnsKeys.STATUS:
        return getStatusText(smsStatus);
      case SmsColumnsKeys.ACTIONS:
        return (
          smsItem.status === SmsStatus.SCHEDULED && (
            <div className='flex gap-1'>
              <Tooltip content="Edit">
                <Edit
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/agencies/${id}/edit-sms/${smsItem.jobId}`)}
                />
              </Tooltip>
              <Tooltip content="Delete">
                <Delete
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => deleteSms(smsItem.jobId)}
                  />
              </Tooltip>
            </div>
          )
        );
      default:
        return '';
    }
  }, []);

  const changeSelection = (smsStatusKey: Key) => {
    if (smsStatusKey === SmsStatus.SCHEDULED) setSelected(SmsStatus.SCHEDULED);
    if (smsStatusKey === SmsStatus.FAILED) setSelected(SmsStatus.FAILED);
    if (smsStatusKey === SmsStatus.SENT) setSelected(SmsStatus.SENT);
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <GoBackButton
        label="Agency's details"
        navigatePath={`/agencies/${id}`}
      />
      <div className="flex flex-row justify-between">
        <PageTitle>SMS for {agency?.name}</PageTitle>
        <Button
          onClick={() => navigate(`/agencies/${id}/new-sms`)}
          style={{
            background: "#6558F5",
            color: "white",
          }}
        >
          New SMS   
        </Button>
      </div>
      <Tabs
        color="secondary"
        className="pl-3"
        aria-label="Tabs colors"
        size="sm"
        onSelectionChange={changeSelection}
      >
        <Tab key={SmsStatus.SCHEDULED} title={`Scheduled`} />
        <Tab key={SmsStatus.SENT} title={`Sent`} />
        <Tab key={SmsStatus.FAILED} title={`Failed`} />
      </Tabs>
      <Table
        aria-label="Users table"
        isStriped
        shadow="md"
        classNames={{
          base: 'max-h-[70vh] overflow-auto p-3',
          table: 'max-h-[50vh]',
        }}
      >
        <TableHeader columns={smsColumns}>
          {(column) => (
            <TableColumn className="bg-purple-500 text-white" key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          items={selectedSmsList}
          emptyContent={'No rows to display.'}
        >
          {(item: SmsItem) => (
            <TableRow key={item.jobId}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default AgencySmsList;