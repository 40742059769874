import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Button = styled.button`
    background: "#6558F5";
    color: "white";
`;

export const Status = styled.span`
    color: "white";
    fontWeight: "bold";
    background: '#2C88D9';
    padding: "8px";
    borderRadius: "20px";
`;

export const centered = styled.div`
    display: flex;
    justify-content: "space-between";
    align-items: "center";
`;