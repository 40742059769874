import { UserContext } from 'src/context/userContext';
import { Button, Checkbox, Spinner } from '@nextui-org/react';
import { Role } from '@components/Sidebar/types';
import moment from 'moment';
import {
  ApproveProposalRequest,
  ProposalObject,
  ProposalStatus as Status,
} from '@services/proposals/types';
import { Fragment, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { approveProposal } from 'src/api/proposal';
import * as S from './styles';

export function ProposalStatus({
  proposal,
  refresh,
}: {
  proposal: ProposalObject;
  refresh: () => void;
}) {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [querying, setQuerying] = useState<boolean>(false);
  const { user } = useContext(UserContext)

  async function approve() {
    try {
      const approveProposalRequest: ApproveProposalRequest = { affinityOpportunityId: proposal.affinityOpportunityId };
      setQuerying(true);
      await approveProposal(approveProposalRequest);
      await refresh();
      toast.success('Proposal succesfully approved');
    } catch (error) {
      toast.error('Error while approving proposal');
      console.log(error);
      throw error;
    } finally {
      setQuerying(false);
    }
  }

  switch (proposal.status) {
    case Status.OPEN:
      return (
        <S.statusContainer>
          <S.statusSubTitlesContainer>
            <S.statusSubTitles>Status: </S.statusSubTitles>
            <S.proposalOpen>Open</S.proposalOpen>
          </S.statusSubTitlesContainer>
          <S.statusSubTitlesContainer>
            <S.statusSubTitles>Proposal Link: </S.statusSubTitles>
            <S.link href={`http://${proposal.proposalLink}`} target="_blank" rel="noopener noreferrer">{proposal.proposalLink}</S.link>
          </S.statusSubTitlesContainer>
          <S.statusSubTitlesContainer>
            <S.statusSubTitles>PandaDoc: </S.statusSubTitles>
            <S.link href={proposal.pandadocContractLink} target="_blank" rel="noopener noreferrer">pandadoclink</S.link>
          </S.statusSubTitlesContainer>
        </S.statusContainer>
      );
    case Status.EXPIRED:
      return (
        <S.statusContainer>
          <S.statusSubTitlesContainer>
            <S.statusSubTitles>Status: </S.statusSubTitles>
            <S.proposalExpired>Expired</S.proposalExpired>
          </S.statusSubTitlesContainer>
          <S.statusSubTitlesContainer>
            <S.statusSubTitles>Proposal Link: </S.statusSubTitles>
            <S.link href={`http://${proposal.proposalLink}`} target="_blank" rel="noopener noreferrer">{proposal.proposalLink}</S.link>
          </S.statusSubTitlesContainer>
        </S.statusContainer>
      );
    case Status.SIGNED:
      return (
        <S.statusContainer>
          <S.statusSubTitlesContainer>
            <S.statusSubTitles>Status: </S.statusSubTitles>
            <S.proposalSigned>Signed</S.proposalSigned>
          </S.statusSubTitlesContainer>
          <S.statusSubTitlesContainer>
            <S.statusSubTitles>Signed At: </S.statusSubTitles>
            <span>{moment(proposal.signedDate).format('MMMM DD, YYYY, HH:MM')}</span>
          </S.statusSubTitlesContainer>
          <S.statusSubTitlesContainer>
            <S.statusSubTitles>Name: </S.statusSubTitles>
            <span>{proposal.signatoryName}</span>
          </S.statusSubTitlesContainer>
          <S.statusSubTitlesContainer>
            <S.statusSubTitles>Email: </S.statusSubTitles>
            <span>{proposal.signatoryEmail}</span>
          </S.statusSubTitlesContainer>
        </S.statusContainer>
      );
    default:
      return (
        <S.statusContainer>
          <S.statusSubTitlesContainer>
            <S.statusSubTitles>Status: </S.statusSubTitles>
            <S.proposalPending>Pending Approval</S.proposalPending>
          </S.statusSubTitlesContainer>
          {user && user.agencyAdmin.role === Role.SUPER_ADMIN && (
            <Fragment>
              <Checkbox isSelected={isSelected} onValueChange={setIsSelected}>
                Mark as approved
              </Checkbox>
              {isSelected && (
                <Button
                  onClick={approve}
                  style={{
                    borderRadius: '0px',
                    backgroundColor: '#6558f5',
                    color: 'white',
                    marginTop: '10px',
                  }}
                >
                  {querying ? <Spinner color="white" /> : <span>Approve and Generate Proposal</span>}
                </Button>
              )}
            </Fragment>
          )}
        </S.statusContainer>
      );
  }
}
