export const getAgencyColumns = (parameter: string) => {
  let columns = [
    {
      key: 'name',
      label: 'AGENCY',
    },
    {
      key: 'dataProvider',
      label: 'DATA PROVIDER',
    },
  ];

  if (parameter === 'active') {
    columns = [
      ...columns,
      {
        key: 'appName',
        label: 'APP NAME',
      },
      {
        key: 'launchDate',
        label: 'LAUNCH DATE',
      },
      {
        key: 'activeCaregivers',
        label: 'ACTIVE CARE TEAM',
      },
    ];
  } else if(parameter === 'preLaunch') {
    columns = [
      ...columns,
      {
        key: 'created',
        label: 'CREATED',
      },
    ];
  } else if(parameter === 'inactive') {
    columns = [
      ...columns,
      {
        key: 'launchDate',
        label: 'LAUNCH DATE',
      },
      {
        key: 'deactivatedDate',
        label: 'DEACTIVATED',
      },
    ];
  }

  columns = [
    ...columns,
    {
      key: 'actions',
      label: 'ACTIONS',
    },
  ];

  return columns;
};
