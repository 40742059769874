import { Button, styled as muiStyled } from '@mui/material'
import { styled } from 'styled-components';

export const EditButton = muiStyled(Button)`
    display: flex;
    justify-content: center;
    gap: 5px;
`;

export const FieldContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;