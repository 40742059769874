import styled from "styled-components";

export const mainContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const header = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const title = styled.span`
    font-size: 24px;
    font-weight: 600;
`;

export const subtitles = styled.span`
    font-size: 18px;
    font-weight: 600;
`;

export const container = styled.div`
    display: flex;
    flex-direction: column;    
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const ActionsContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
`;
