import { NextUIProvider } from "@nextui-org/react";
import Agencies from "@pages/Agencies";
import AgencyDetais from "@pages/Agencies/Details";
import Home from "@pages/Home";
import Login from "@pages/Login";
import Users from "@pages/Users";
import UserDetails from "@pages/Users/Details";
import AgencySmsList from "@pages/Agencies/SmsManager/SmsList";
import AgencyNewSms from "@pages/Agencies/SmsManager/NewSms";
import AgencyEditSms from "@pages/Agencies/SmsManager/EditSms";
import { theme } from "@styles/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import { Proposal } from "@pages/Proposal";
import { ProposalDetails } from "@pages/Proposal/components/proposalDetails";
import { ProposalContactPage } from "@pages/ProposalContact";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "react-phone-number-input/style.css";
import { UserProvider } from "./context/userContext";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <NextUIProvider>
    <ThemeProvider theme={theme}>
      <UserProvider>
        <QueryClientProvider client={queryClient}>
          <main className="light text-foreground bg-background">
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<App />}>
                  <Route index element={<Home />} />
                  <Route path="/users">
                    <Route index element={<Users />} />
                    <Route path=":id" element={<UserDetails />} />
                  </Route>
                  <Route path="/agencies">
                    <Route index element={<Agencies />} />
                    <Route path=":id" element={<AgencyDetais />} />
                    <Route path=":id/sms-manager" element={<AgencySmsList />} />
                    <Route path=":id/new-sms" element={<AgencyNewSms />} />
                    <Route path=":id/edit-sms/:jobId" element={<AgencyEditSms />} />
                  </Route>
                  <Route path="/proposal">
                    <Route index element={<Proposal />} />
                    <Route path=":id" element={<ProposalDetails />} />
                  </Route>
                  <Route path="/proposal-contact" element={<ProposalContactPage />} />
                </Route>
                <Route path="*" element={<>Not found</>} />
              </Routes>
            </BrowserRouter>
          </main>
        </QueryClientProvider>
      </UserProvider>
    </ThemeProvider>
  </NextUIProvider>
);

reportWebVitals();
