import PageTitle from "@components/PageTitle";
import { Button, Spinner, Tab, Tabs, useDisclosure } from "@nextui-org/react";
import { ProposalObject, ProposalStatus } from "@services/proposals/types";
import { useEffect, useState } from "react";
import { getProposalList } from "src/api/proposal";
import { ModalProposal } from "./components/modalProposal";
import { ProposalTable } from "./components/proposalTable";
import * as S from "./styles";

export function Proposal() {
    const [proposalList, setProposalList] = useState<ProposalObject[]>([]);
    const [pendingApprovalProposalList, setPendingApprovalProposalList] = useState<ProposalObject[]>([]);
    const [openedProposalList, setOpenedProposalList] = useState<ProposalObject[]>([])
    const [signedProposalList, setSignedProposalList] = useState<ProposalObject[]>([])
    const [expiredProposalList, setExpiredProposalList] = useState<ProposalObject[]>([])
    const [querying, setQuerying] = useState<boolean>(false);

    const { isOpen, onOpen, onClose } = useDisclosure();
    /* eslint-disable no-param-reassign */
    async function refresh() {
        try {
            setQuerying(true)
            const proposals = await getProposalList()
            proposals.map(proposal => {
                if (proposal.status === ProposalStatus.PENDING_APPROVAL || proposal.status === ProposalStatus.OPEN) {
                    const todayDate = new Date()
                    const proposalExpirationDate = new Date(proposal.proposalExpirationDate)
                    const diff = Math.abs(proposalExpirationDate.getTime()) - todayDate.getTime();
                    const diffInDays = Math.round(diff / (1000 * 60 * 60 * 24));
                    if (diffInDays < 0) proposal.status = ProposalStatus.EXPIRED
                }
                return proposal
            })
            setPendingApprovalProposalList(proposals.filter(proposal => proposal.status === ProposalStatus.PENDING_APPROVAL))
            setOpenedProposalList(proposals.filter(proposal => proposal.status === ProposalStatus.OPEN))
            setSignedProposalList(proposals.filter(proposal => proposal.status === ProposalStatus.SIGNED))
            setExpiredProposalList(proposals.filter(proposal => proposal.status === ProposalStatus.EXPIRED))
            setProposalList(proposals)
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setQuerying(false)
        }
    }
    /* eslint-enable no-param-reassign */

    useEffect(() => {
        refresh()
    }, [])

    return (
        <div>
            <S.Container>
                <PageTitle>Proposal</PageTitle>
                <Button
                    onClick={onOpen}
                    style={{
                        background: "#6558F5",
                        color: "white",
                    }}
                >
                    New Proposal
                </Button>
            </S.Container>
            <Tabs aria-label="Open proposal tabs">
                <Tab key="all" title="All">
                    {!querying ? <ProposalTable proposalList={proposalList} /> : <Spinner color="primary" />}
                </Tab>
                <Tab key="pendingApproval" title="Pending Approval">
                    {!querying ? <ProposalTable proposalList={pendingApprovalProposalList} /> : <Spinner color="primary" />}
                </Tab>
                <Tab key="open" title="Open">
                    {!querying ? <ProposalTable proposalList={openedProposalList} /> : <Spinner color="primary" />}
                </Tab>
                <Tab key="signed" title="Signed">
                    {!querying ? <ProposalTable proposalList={signedProposalList} /> : <Spinner color="primary" />}
                </Tab>
                <Tab key="expired" title="Expired">
                    {!querying ? <ProposalTable proposalList={expiredProposalList} /> : <Spinner color="primary" />}
                </Tab>
            </Tabs>
            <ModalProposal refresh={refresh} onClose={onClose} isOpen={isOpen} />
        </div>
    )
}